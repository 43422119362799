

























































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import ChargePointApi from '../../services/ChargePointApi'
import TokenApi from '../../services/TokenApi'
import triggerWhenUserIsVerified from '@/functions/triggerWhenUserIsVerified'
import moment from 'moment'
import ChargerOverview from './ChargerOverview.vue'
import CardsOverview from './CardsOverview.vue'
import { mapGetters } from 'vuex'

@Component({
  data: () => ({
    btns: [['Total']],
    colors: ['deep-purple accent-4', 'error', 'teal darken-1'],
    items: ['Total', 'Average'],
    btnsType: [['Chargers']],
    itemsType: ['Chargers', 'Charge Pass']
  }),
  components: {
    ChargerOverview,
    CardsOverview
  },
  computed: {
    ...mapGetters({ userDT: 'user/userInfo' })
  },
  filters: {
    formatDateMonth(val: string) {
      return moment(val).format('MMMM-YYYY')
    },
    formatEuro(val: number) {
      let valRt: string | number = 0
      if (val >= 0) {
        valRt = val.toFixed(2)
      }
      return valRt
    }
  }
})
export default class Dashboard extends Vue {
  loadTxt = this.t('$vuetify.dashboard.loading')
  date = new Date().toISOString().substr(0, 7)
  menu = false
  currentConsumption = { label: 'Chargers', icon: 'mdi-ev-station' }
  currentTypeConsumption = { label: 'Total', icon: 'mdi-sigma' }
  curYear = '0'
  overview = {
    showDT: false,
    currentDT: new Date().toISOString().substr(0, 7),
    loading: true,
    chargersOverview: {
      totalKwH: 0,
      details: [],
      totalTime: 0,
      count: 0,
      my: 0,
      sub: 0
    },
    cardsOverview: {
      totalKwH: 0,
      totalTime: 0,
      count: 0,
      details: [],
      my: 0,
      sub: 0
    },
    other: {
      coSaved: 0,
      kwhAllDrivers: 0,
      kwhCurrent: 0,
      totalChargers: 0
    },
    KWHMyChargersTab: [],
    KWHMyTokensTab: []
  }

  options = {
    tooltip: {
      theme: 'dark'
    },
    chart: {
      id: 'vuechart-example',
      height: '150px',
      toolbar: {
        show: false
      }
    },
    yaxis: {},
    xaxis: {
      categories: [
        this.t('$vuetify.months.january'),
        this.t('$vuetify.months.february'),
        this.t('$vuetify.months.march'),
        this.t('$vuetify.months.april'),
        this.t('$vuetify.months.may'),
        this.t('$vuetify.months.june'),
        this.t('$vuetify.months.july'),
        this.t('$vuetify.months.august'),
        this.t('$vuetify.months.september'),
        this.t('$vuetify.months.october'),
        this.t('$vuetify.months.november'),
        this.t('$vuetify.months.december')
      ]
    },
    stroke: {
      curve: 'smooth'
    }
  }

  series = [
    {
      name: 'series-1',
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  ]

  topConsumptions = {
    month: 0,
    total: 0
  }

  graphIndicator: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  KWHMyChargersTab: any = []
  KWHMyTokensTab: any = []

  mounted() {
    moment.locale(this.t('$vuetify.profile.locale'))
    if (this.userDTA.verified) {
      this.customMounted()
    } else {
      triggerWhenUserIsVerified(this.customMounted)
    }
    localStorage.name = 'TEST'
  }

  fetchTotal() {
    this.currentTypeConsumption = { label: 'Total', icon: 'mdi-sigma' }
    if (this.currentConsumption.label === 'Charge Pass') {
      this.fetchIndicators()
    } else {
      this.fetchChargers()
    }
  }

  fetchAverage() {
    this.currentTypeConsumption = { label: 'Average', icon: 'mdi-counter' }
    if (this.currentConsumption.label === 'Chargers') {
      this.fetchAVGChargers()
    } else {
      this.fetchAVGIndicators()
    }
  }

  toKwHVal(val: number) {
    return  Math.trunc( val )
  }

  get userDTA(): any {
    // @ts-ignore
    return this.userDT
  }



  async fetchChargers() {
    this.currentConsumption = { label: 'Chargers', icon: 'mdi-ev-station' }
    if (this.currentTypeConsumption.label === 'Average') {
      this.fetchAVGChargers()
    } else {
      this.graphIndicator = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      const curYear = parseInt(moment(this.overview.currentDT).format('YYYY'))
      if (this.KWHMyChargersTab.length > 0) {
        for (const element of this.KWHMyChargersTab) {
          if (element.year === curYear) {
            this.graphIndicator[element.label - 1] = this.toKwHVal(
              element.value
            )
          }
        }
      } else {
        for (let index = 0; index < this.graphIndicator.length; index++) {
          this.graphIndicator[index] = 0
        }
        this.topConsumptions = {
          month: 0,
          total: 0
        }
      }
      this.series = [
        {
          name: 'Total kWh ',
          data: this.graphIndicator
        }
      ]
    }
  }

  async fetchAVGChargers() {
    this.currentConsumption = { label: 'Chargers', icon: 'mdi-ev-station' }
    this.graphIndicator = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    const curYear = parseInt(moment(this.overview.currentDT).format('YYYY'))
    if (this.KWHMyChargersTab.length > 0) {
      for (const element of this.KWHMyChargersTab) {
        if (element.year === curYear) {
          this.graphIndicator[element.label - 1] = this.toKwHVal(
            element.value / element.details.length
          )
        }
      }
    } else {
      for (let index = 0; index < this.graphIndicator.length; index++) {
        this.graphIndicator[index] = 0
      }
      this.topConsumptions = {
        month: 0,
        total: 0
      }
    }
    this.series = [
      {
        name: 'AVG kWh ',
        data: this.graphIndicator
      }
    ]
  }

  formatDTAChargers() {
    if (this.KWHMyChargersTab && this.KWHMyChargersTab.length > 0) {
      for (const element of this.KWHMyChargersTab) {
        if (element.value > 0) {
          element.value = element.value / 1000
        }
      }
    }
  }

  formatDTATokens() {
    if (this.KWHMyTokensTab && this.KWHMyTokensTab.length > 0) {
      for (const element of this.KWHMyTokensTab) {
        if (element.total > 0) {
          element.total = element.total / 1000
        }
      }
    }
  }

  async refresh() {
    this.curYear = moment(this.overview.currentDT).format('YYYY')
    this.overview.loading = true
    this.loadTxt = this.t('$vuetify.dashboard.loadingTokens')

    const kwhmytokensPromise = new TokenApi()
      .getIndicatorType('KWHMyTokens')
      .then((dt: any) => {
        this.KWHMyTokensTab = dt.data
        this.overview.KWHMyTokensTab = this.KWHMyTokensTab
        this.formatDTATokens()
        this.initTotalChargeCard()
        this.fetchIndicators()
      })
    this.loadTxt = this.t('$vuetify.dashboard.loadingChargers')
    const kwhmychargersPromise = new TokenApi()
      .getIndicatorType('KWHMyChargers')
      .then((dt: any) => {
        this.KWHMyChargersTab = dt.data
        this.overview.KWHMyChargersTab = this.KWHMyChargersTab
        this.formatDTAChargers()
        this.initTotalChargers()
      })

    this.loadTxt = this.t('$vuetify.dashboard.loadingCPIndicators')
    const dateRange = {
      start: moment(this.overview.currentDT)
        .startOf('month')
        .format('YYYY-MM-DD 00:00:00'),
      end: moment(this.overview.currentDT)
        .endOf('month')
        .format('YYYY-MM-DD 00:00:00')
    }
    const chargerstatsPromise = new ChargePointApi()
      .getIndicators(dateRange)
      .then((dt: any) => {
        this.overview.chargersOverview.my = dt.data.mychargers
        this.overview.chargersOverview.sub = dt.data.subchargers
      })
    this.loadTxt = this.t('$vuetify.dashboard.loadingTokensIndicators')
    const tokenstatsPromise = new TokenApi().getIndicators().then((dt: any) => {
      this.overview.cardsOverview.my = dt.data.mytokens
      this.overview.cardsOverview.sub = dt.data.subtokens
    })

    Promise.all([
      tokenstatsPromise,
      chargerstatsPromise,
      kwhmytokensPromise,
      kwhmychargersPromise
    ]).then(() => {
      this.overview.loading = false
    })
  }

  async customMounted() {
    this.refresh()
  }

  async fetchIndicators() {
    this.currentConsumption = {
      label: 'Charge Pass',
      icon: 'uil uil-credit-card'
    }
    if (this.currentTypeConsumption.label === 'Average') {
      this.fetchAVGIndicators()
    } else {
      this.graphIndicator = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      const curYear = parseInt(moment(this.overview.currentDT).format('YYYY'))
      const KWHMyTokens = this.KWHMyTokensTab
      this.topConsumptions = {
        month: 0,
        total: 0
      }
      let topMonth = 0
      if (KWHMyTokens.length > 0) {
        for (const element of KWHMyTokens) {
          if (element.year === curYear) {
            this.graphIndicator[element.month - 1] = this.toKwHVal(
              element.total
            )
            if (element.total > topMonth) {
              topMonth = element.total
              this.topConsumptions.month = element.month
              this.topConsumptions.total = element.total
            }
          }
        }
      } else {
        for (let index = 0; index < this.graphIndicator.length; index++) {
          this.graphIndicator[index] = 0
        }
        this.topConsumptions = {
          month: 0,
          total: 0
        }
      }
      this.series = [
        {
          name: 'Total kWh ',
          data: this.graphIndicator
        }
      ]
    }
  }

  async fetchAVGIndicators() {
    this.currentConsumption = {
      label: 'Charge Pass',
      icon: 'uil uil-credit-card'
    }
    this.graphIndicator = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    const curYear = parseInt(moment(this.overview.currentDT).format('YYYY'))
    const KWHMyTokens = this.KWHMyTokensTab
    this.topConsumptions = {
      month: 0,
      total: 0
    }
    let topMonth = 0
    if (KWHMyTokens.length > 0) {
      for (const element of KWHMyTokens) {
        if (element.year === curYear) {
          this.graphIndicator[element.month - 1] = this.toKwHVal(
            element.total / element.details.length
          )
          if (element.total > topMonth) {
            topMonth = element.total
            this.topConsumptions.month = element.month
            this.topConsumptions.total = element.total
          }
        }
      }
    } else {
      for (let index = 0; index < this.graphIndicator.length; index++) {
        this.graphIndicator[index] = 0
      }
      this.topConsumptions = {
        month: 0,
        total: 0
      }
    }

    this.series = [
      {
        name: 'AVG kWh ',
        data: this.graphIndicator
      }
    ]
  }

  initTotalChargeCard() {
    let tmpDate = moment(this.overview.currentDT).format('MM')
    if (parseInt(tmpDate) < 10) {
      tmpDate = moment(this.overview.currentDT).format('M')
    }
    if (this.KWHMyTokensTab.length > 0) {
      const foundVal = this.KWHMyTokensTab.filter(
        (c: any) =>
          c.month === parseInt(tmpDate) && c.year === parseInt(this.curYear)
      )
      if (foundVal.length > 0) {
        this.overview.cardsOverview.totalKwH = foundVal[0].total
        this.overview.cardsOverview.totalTime = foundVal[0].totaltime
        this.overview.cardsOverview.count = foundVal[0].count
      } else {
        this.overview.cardsOverview.totalKwH = 0
        this.overview.cardsOverview.totalTime = 0
        this.overview.cardsOverview.count = 0
      }
    }
  }

  initTotalChargers() {
    let tmpDate = moment(this.overview.currentDT).format('MM')
    if (parseInt(tmpDate) < 10) {
      tmpDate = moment(this.overview.currentDT).format('M')
    }
    if (this.KWHMyChargersTab.length > 0) {
      const foundVal = this.KWHMyChargersTab.filter(
        (c: any) =>
          c.label === parseInt(tmpDate) && c.year === parseInt(this.curYear)
      )
      if (foundVal.length > 0) {
        this.overview.chargersOverview.totalKwH = foundVal[0].value
        this.overview.chargersOverview.totalTime = foundVal[0].totaltime
        this.overview.chargersOverview.count = foundVal[0].count
      } else {
        this.overview.chargersOverview.totalKwH = 0
        this.overview.chargersOverview.totalTime = 0
        this.overview.chargersOverview.count = 0
      }
    } else {
      this.overview.chargersOverview.totalKwH = 0
      this.overview.chargersOverview.totalTime = 0
      this.overview.chargersOverview.count = 0
    }
  }
}
