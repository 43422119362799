export const calculateKwh = (value: number | string): number => {
  if (value > 0) {
    if (typeof value !== 'number') {
      value = parseInt(value)
    }
    return value
  } else {
    return 0
  }
}

export const calculateDaysHoursMinutes = (value: number): string => {
  if (value > 0) {
    const secNum = parseInt(String(value), 10)
    const days: number = Math.floor(secNum / 86400)
    const rMSD = secNum - days * 86400
    let hours: string | number = Math.floor(rMSD / 3600)
    const rMSH = rMSD - hours * 3600
    let minutes: string | number = Math.floor(rMSH / 60)
    if (hours < 10) {
      hours = '0' + hours
    }
    if (minutes < 10) {
      minutes = '0' + minutes
    }
    return days + ' d ' + hours + ' h ' + minutes + ' m'
  } else {
    return 0 + ' d ' + 0 + ' h ' + 0 + ' m'
  }
}

export const roundToTwoDecimals = (value: number) => {
  let valRt = '0'
  if (value >= 0) {
    valRt = value.toFixed(2)
  }

  return valRt
}
