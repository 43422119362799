import store from '../store'

/* how to use:

import triggerWhenUserIsVerified from '@/functions/triggerWhenUserIsVerified'
triggerWhenUserIsVerified(doWhenOke)

doWhenOke () {
  // code to run when user is verified
  console.log('running after login is oke')
}

or

triggerWhenUserIsVerified(() => doStuff())

*/
// @ts-ignore
export default function triggerWhenUserIsVerified (callback: () => void) {
  store.subscribe((mutation: any, state: any) => {
    if (mutation.type === 'user/setUserInfo') {
      if (mutation.payload.verified) {
        callback()
      }
    }
  })
}
