var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"grid-list-lg":""}},[_c('v-layout',{staticClass:"mt-2",attrs:{"align-center":"","row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{staticClass:"rounded-card",attrs:{"flat":"","color":"card-title"}},[_c('v-card-title',{class:_vm.$vuetify.breakpoint.xs ? 'text-center' : 'pa-0 pb-1'},[_c('h1',{class:_vm.$vuetify.breakpoint.xs ? 'headline pb-2' : 'headline pb-2'},[_vm._v(" "+_vm._s(_vm.t('$vuetify.dashboard.title'))+": "+_vm._s(_vm._f("formatDateMonth")(_vm.overview.currentDT))+" ")]),_c('v-spacer'),_c('div',[_c('span',{staticClass:"body-1"},[_vm._v(_vm._s(_vm.overview.currentDT))]),_c('v-menu',{ref:"overviewDT",attrs:{"close-on-content-click":false,"return-value":_vm.overview.currentDT,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"190px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.overview, "currentDT", $event)},"update:return-value":function($event){return _vm.$set(_vm.overview, "currentDT", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","text":""}},on),[_c('v-icon',{staticClass:"mr-2",attrs:{"medium":""}},[_vm._v(" mdi-calendar-edit")])],1)]}}]),model:{value:(_vm.overview.showDT),callback:function ($$v) {_vm.$set(_vm.overview, "showDT", $$v)},expression:"overview.showDT"}},[_c('v-date-picker',{attrs:{"type":"month","no-title":"","scrollable":"","locale":_vm.t('$vuetify.profile.local')},model:{value:(_vm.overview.currentDT),callback:function ($$v) {_vm.$set(_vm.overview, "currentDT", $$v)},expression:"overview.currentDT"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.overview.showDT = false}}},[_vm._v(" "+_vm._s(_vm.t('$vuetify.generic.cancel'))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.overviewDT.save(_vm.overview.currentDT)
                      _vm.refresh()}}},[_vm._v(" "+_vm._s(_vm.t('$vuetify.generic.ok'))+" ")])],1)],1)],1)],1)],1)],1)],1),_c('v-layout',{attrs:{"row":"","wrap":""}},[(!_vm.$vuetify.breakpoint.xs)?_c('v-flex',{attrs:{"lg12":""}},[_c('v-divider')],1):_vm._e()],1),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"lg6":"","sm12":"","xs12":""}},[_c('charger-overview',{attrs:{"isLoading":_vm.overview.loading,"overview":_vm.overview.chargersOverview}})],1),_c('v-flex',{attrs:{"lg6":"","sm12":"","xs12":""}},[_c('cards-overview',{attrs:{"isLoading":_vm.overview.loading,"overview":_vm.overview.cardsOverview}})],1)],1),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{staticClass:"pa-0 rounded-card",staticStyle:{"border-radius":"8px"},attrs:{"flat":"","outlined":"","color":"#FFFFFF","elevation":"0"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.t('$vuetify.dashboard.consumptions'))+" - "+_vm._s(_vm.curYear)+" "),_c('v-spacer'),_c('div',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-filter")]),_c('span',{staticClass:"subtitle-1"},[_vm._v("Filters :")]),_c('v-menu',{attrs:{"offset-y":"","left":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"width":"auto"},attrs:{"text":"","icon":_vm.$vuetify.breakpoint.smAndDown}},on),[_c('v-icon',{staticClass:"mx-2",attrs:{"right":""}},[_vm._v(_vm._s(_vm.currentTypeConsumption.icon)+" ")]),_vm._v(" "+_vm._s(_vm.currentTypeConsumption.label)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('v-list',{attrs:{"dense":"","nav":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.fetchTotal()}}},[_c('v-list-item-title',{staticClass:"mx-2"},[_c('v-icon',[_vm._v("mdi-sigma")]),_vm._v(" Total ")],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.fetchAverage()}}},[_c('v-list-item-title',{staticClass:"mx-2"},[_c('v-icon',[_vm._v("mdi-counter")]),_vm._v(" Average ")],1)],1)],1)],1),_c('v-menu',{attrs:{"offset-y":"","left":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"width":"auto"},attrs:{"text":"","icon":_vm.$vuetify.breakpoint.smAndDown}},on),[_c('v-icon',{staticClass:"mx-2",attrs:{"right":""}},[_vm._v(_vm._s(_vm.currentConsumption.icon)+" ")]),_vm._v(" "+_vm._s(_vm.currentConsumption.label)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('v-list',{attrs:{"dense":"","nav":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.fetchChargers()}}},[_c('v-list-item-title',{staticClass:"mx-2"},[_c('v-icon',[_vm._v("mdi-ev-station")]),_vm._v(" Charger ")],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.fetchIndicators()}}},[_c('v-list-item-title',{staticClass:"mx-2"},[_c('v-icon',[_vm._v("uil uil-credit-card")]),_vm._v(" Charge Pass ")],1)],1)],1)],1)],1)],1),(_vm.overview.loading)?_c('v-card-text',{staticClass:"ma-5",attrs:{"flat":""}},[_c('v-skeleton-loader',{ref:"skeleton",staticClass:"mx-auto",attrs:{"type":"article"}})],1):_c('apexchart',{attrs:{"height":"300","type":"area","options":_vm.options,"series":_vm.series}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }