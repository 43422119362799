
































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { OverviewInterface } from '@/typings/Dashboard'
import {
  calculateDaysHoursMinutes,
  calculateKwh,
  roundToTwoDecimals
} from '@/functions/dashboardCalculations'

@Component({
  filters: {
    toKwH(val: number | string) {
      return calculateKwh(val)
    },
    toHMS(val: number) {
      return calculateDaysHoursMinutes(val)
    },
    formatTwoDecimals(val: number) {
      return roundToTwoDecimals(val)
    }
  }
})
export default class ChargerOverview extends Vue {
  @Prop(Object) readonly overview!: OverviewInterface
  @Prop(Boolean) readonly isLoading: boolean = true
}
